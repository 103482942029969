.order-box-status {
    padding: 15px;
    background: white;
    border-radius: 4px;
    margin-top: 15px;
    min-height: 230px;
  }
  .status-select-dropdown {
    width: 30%;
    margin-left: auto;
  }
  .cancelled-status {
    color: red;
  }
  .completed-status {
    color: green;
  }