@import "../../constants/colors.scss";
.profile-section {
    position: relative;
    .main-heading{
        color: #14141499;
        font-size: 14px;
        margin-bottom: 15px;
    }
    .heading {
        color: #141414;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 8px;
    }
    .heading-val {
        color: #666666;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 8px;
    }
    .static-qr-section {
        text-align: center;
        padding: 0 15px;
        font-weight: 700;
        img{
            width: 90%;
        }
    }
}
.logout-btn {
    color: blue;
    cursor: pointer;
    text-decoration: none;
    position: absolute;
    top: 8px;
    right: 0;
    width: 75px;
    font-size: 15px;
    font-weight: bold;
    margin-top: 5px;
}
.table-qr-section{
    text-align: center;
    width: 35%;
    padding: 20px;
    color: black;
    background-color: white;
}
.table-qr-section .footer{
    margin: 0 -20px -20px -20px;
    padding: 7px;
    background: rgb(5 68 215);
    font-size: 20px;
    font-weight: bold;
    color: #00ffd0;
}
.table-qr-section .qr-img {
    width: 80%;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0px 0px 3px 0px #ababab;
    margin: 10px;
}
.table-qr-section .terminal-table-img {
    width: 15%;
    margin-bottom: 10px;
}
.table-qr-section label {
    margin: 0 5px; 
}
.profile-left, .profile-right {
    background: white;
    padding: 15px;
    border-radius: 4px;
    margin-top: 5px;
}