@import "../../constants/colors.scss";

.button-normal-style {
  padding: 8px 20px;
  font-size: 16px;
  line-height: 21px;
  width: 100%;
  max-width: 450px;
  font-weight: bold;
  color: $white;
  background-color: blue;
  border: none;
  border-radius: 8px;
  &:disabled {
    background-color: #D0CDF6;
    color: #f1f1f1;
  }
  &:focus {
    background-color: #084298;
    color: #f1f1f1;
  }
  cursor: pointer;
}

.button-link-style {
  padding: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $mediumSlateBlue;
  background-color: transparent;
  border: none;
  &:disabled {
    background-color: grey;
    color: $white;
  }
  cursor: pointer;
}
