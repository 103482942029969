.customer-outer-section {
    background: white;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 5px 15px;
    .cust-main-heading {
        font-weight: 600;
        padding-bottom: 10px;
        // border-bottom: 1px solid lightgray;
    }
    .cust-inner-heading {
        font-weight: 600;
        color: #01b59c;
        i {
            margin-right: 5px;
        }
    }
}
.customer-map-btn {
    text-decoration: none;
    color: orange;
    cursor: pointer;
    margin-top: 10px;
    i {
        margin-right: 10px;
    }
}
.customer-map-btn:hover {
    color: orange;
}
.select_date_slot {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 24.55px;
    column-gap: 44.13px;
    margin-bottom: 37.55px;
}
.date_slot {
    cursor: pointer;
    box-shadow: #959da533 0 8px 24px;
    border: .971px solid #01b59c;
    background-color: #fff;
    border-radius: 15px;
    text-align: center;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-style: normal;
    line-height: normal;
    padding: 10px;
    font-size: 20px;
    height: 75px;
    width: 104px;
    .date {
        color: #4c4b4b;
        font-weight: 600;
        padding: 3px;
        margin: 0;
        font-size: 18px;
    }
    .day {
        font-weight: 500;
        color: #01b59c;
        padding: 3px;
        text-transform: uppercase;
        margin: 0;
        font-size: 18px;
    }
}
.active_date_slot {
    border-radius: 9.715px;
    border: .971px solid #01b59c;
    background-color: #01b59c;
    color: #fff;
    flex-shrink: 0;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    .date {
        color: white;
    }
    .day {
        color: white;
    }
}
.select_time_slot {
    grid-template-columns: repeat(auto-fit, minmax(122px, 1fr));
    display: grid;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 20px;
    column-gap: 42.74px;
    border-radius: 15.544px;
    border: .971px solid #01b59c;
    background: #01b59c03;
    box-shadow: 0 7.772px 7.772px #0000000d;
}
.time_slot {
    // width: 150.748px;
    font-size: 18px;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 9.715px;
    font-weight: 600;
    border: .971px solid #01b59c;
    font-style: normal;
    line-height: normal;
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 8px;
    height: 48.574px;
    background-color: #fff;
    span {
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
.active_time_slot {
    color: white;
    background-color: #01b59c;
}
.right_section {
    border-radius: 9.715px;
    border: .971px solid #01b59c;
}

.btn_section {
    text-align: center;
    margin: 15px 0;
}
.cancel-btn{
    background: orange;
    border: 1px solid orange;
}
.cancel-btn:hover {
    background: orange;
    border: 1px solid orange;
}
.submit-btn{
    background: #01b59c;
    border: 1px solid #01b59c;
    margin-left: 15px;
}
.submit-btn:hover {
    background: #01b59c;
    border: 1px solid #01b59c;
}
.address_type_section {
    display: flex;
    margin-bottom: 10px;
    label {
        width: 10%;
        margin: auto 0;
    }
    .chip_outer {
        width: 30%;
    }
    .MuiChip-root {
        width: 80%;
    }
}
.amt_label {
    float: right;
    font-size: 20px;
    margin: 15px 0;
}
.delete_item {
    text-align: center;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}