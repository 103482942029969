@import "../../../constants/colors.scss";

.sidebar-main-container {
 max-width: 260px;
 width: 100%;
 border-right: 2px solid white;
 background-color: white;
 height: 87vh;
 margin: 10px;
 margin-right: 0;
 border-radius: 4px;
 padding-top: 5px;
 transition: 0.5s;
 overflow-y: auto;
}
.hide-nav-main {
  width: 60px;
  transition: 0.5s;
}
.list-icon {
  // position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  padding: 15px;
  background-color: white;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
.list-icon-hide {
  position: absolute;
  top: 10px;
  right: 0;
  width: 55px;
  padding: 15px;
  background-color: white;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.sidebar-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  padding: 0px 20px;
  cursor: pointer;
  margin: 0 12px 0 15px;
  border-radius: 4px;
  transition: 0.5s;
}
.sidebar-item-icon-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}
.sidebar-number-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 35px;
  background-color: $mediumSlateBlue;
  border-radius: 8px;
  margin-left: 35px;
}


.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .collapsible:hover {
  background-color: #00AB92;
  border-radius: 4px;
  color: white;
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}