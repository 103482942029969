@import "../../../constants/colors.scss";

.toast-box-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: 50px;
  border-radius: 8px;
  padding: 0px 24px;
  margin: 5px;
  pointer-events: auto;
}

.toast-box-success-style {
  background-color: $whiteIce;
  border: 1px solid $mediumAquamarine;
}

.toast-box-error-style {
  background-color: $roseWhite;
  border: 1px solid $atomicTangerine;
}

.toast-box-warning-style {
  background-color: $cornSilk;
  border: 1px solid $kournikova;
}

.toast-box-left-side-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85%;
  padding-right: 12px;
}

.toast-box-right-side-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 15%;
}

.toast-box-status-text-style {
  padding: 0px 18px 0px 12px;
}

.toast-box-menu-button-style {
  background-color: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 360px;
  cursor: pointer;
}
