@import "../../constants/colors.scss";

.toast-main-container {
  position: fixed;
  z-index: 2000;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: end;
  pointer-events:none; 
  animation-duration: 4s;
}
