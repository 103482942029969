$white: #ffffff;
$black: #000000;
$blackTransparent: rgba(0, 0, 0, 0.8);
$hawkesBlue: #d5d9e0;
$aliceBlue: #f7faff;
$mediumSlateBlue: #6e62e3;
$scarletRed: #ff1f1f;
$dimGray: #707070;
$whisper: #ededed;
$lavender: #efeffa;
$whiteIce: #d5ffed;
$mediumAquamarine: #65e6af;
$roseWhite: #ffefea;
$atomicTangerine: #ff8f6b;
$cornSilk: #fff6dd;
$kournikova: #ffd24d;
$coral: #ff7a4f;
$shamrock: #29cb86;
$saffron: #f3c029;
$nero: #242424;
$grey: #797979;
$whiteSmoke: #f5f5f5;
$whiteSmokeDark: #EFEFEF;
$portage: #9092fa;
$bananaMania: #ffedb5;
$veryLightGrey: #CBCBCB;
$darkGray: #A3A3A3;
$horsesNeck: #6E5916;
$eclipse: #383838;
$greySuit: #8D8C91;
$ghostWhite: #F7F6FF;
$solitude: #EDF0FA;
