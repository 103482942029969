@import "../../constants/colors.scss";


.login-page-illustration-style{
    width: 100%;
}
.login-page-right-side-style{
background-color: "#ffa5001f";
}
.login-page-input-right-side-icon-style {
    width: 20px;
    height: auto;
  }
  .forgot-page-fields {
    max-width: 450px;
  }  