@import "./colors.scss";

.container-fluid {
  display: flex;
  height: 100vh;
  padding: 0px;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.container-fluid::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.row {
  width: 100%;
  margin: 0px;
}
.display-flex-column-align-center-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
}

.text-center-align {
  text-align: center;
}

.dashboard-pages-reusable-style {
  padding: 50px 60px 50px 25px;
  display: flex;
  flex-direction: column;
  background-color: $aliceBlue;
  overflow-x: hidden;
}
.dashboard-pages-reusable-title-style {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 26px;
}

.cursor-pointer {
  cursor: pointer;
}

.fw-l {
  font-weight: 300;
}
.fw-b {
  font-weight: bold;
}
.fw-m {
  font-weight: 500;
}
.fw-eb {
  font-weight: 800;
}

.fs-26 {
  font-size: 26px;
}
.fs-24 {
  font-size: 24px;
}
.fs-22 {
  font-size: 22px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}
.fs-10 {
  font-size: 10px;
}

.fc-dimGrey {
  color: $dimGray;
}
.fc-nero {
  color: $nero;
}
.fc-grey {
  color: $grey;
}
.fc-scarletRed {
  color: $scarletRed;
}
.fc-white {
  color: $white;
}
.fc-coral {
  color: $coral;
}
.fc-saffron {
  color: $saffron;
}
.fc-shamrock {
  color: $shamrock;
}
.fc-darkGray {
  color: $darkGray;
}
.fc-mediumSlateBlue {
  color: $mediumSlateBlue;
}
.fc-eclipse {
  color: $eclipse;
}
.fc-greySuit {
  color: $greySuit;
}



.bg-aliceBlue {
  background-color: $aliceBlue;
}

.bg-lavender {
  background-color: $lavender;
}

.bg-whiteSmoke {
  background-color: #00AB92;
  .text-main-style {
    color: white;
  }
}

.right-head-section {
  background: white;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 5px 15px;
}
.transporter-list{
  width: 30%;
  position: absolute;
  top: 5px;
  right: 15px;
}


.order-outer-section {
  height: 75vh;
  position: relative;
  margin-top: 5px;
  .card {
      height: 100%;
  }
  .card-header, .card-footer {
    background-color: rgb(255 255 255 / 3%);
  }
  .card-body {
      height: 100%;
      overflow: auto;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
  }
  .card-body::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }
  .confirm-btn {
     float: right;
  }
  .tag-no-field {
      color: blue;
      cursor: pointer;
      font-weight: bold;
  }
}

.order-detail-outer {
  .main-heading{
      color: #14141499;
      font-size: 14px;
      margin-bottom: 15px;
  }
  .heading {
      color: #141414;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 8px;
  }
  .heading-val {
      color: #666666;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 8px;
  }
  .print-btn {
      float: right;
      margin-right: 5px;
      background-color: blue;
  }
}

.order-row {
  color: #738098;
  padding: 10px;
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  .order-created, .order-qty {
    float: right;
  }
}
.expire-drop-order {
  border-color: red !important;
  background-color: #fdebcb;
}
.no-data {
  text-align: center;
}
.modal-backdrop{
  background: #00000059;
}
.modal-backdrop.show {
  opacity: 0.3;
}