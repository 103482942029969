@import "../../constants/colors.scss";

.dashboard-main-container {
    display: inline-table;
    flex-direction: column;
    background-color: #E2E5EA;
}
.dashboard-main-inside-container{
    display: flex;
    flex-direction: row;
}