@import "../../constants/colors.scss";
.barcode-label {
    float: left;
    padding: 0;
    width: auto;
    position: relative;
    text-align: center;
}
.verify-img {
    width: 25%;
    position: absolute;
    top: 23%;
    left: 38%;
}
#div-svg {
    display: none;
}
.order-detail-modal-header h5 {
    width: 100%;
}
.download-invoice {
    cursor: pointer;
    float: right;
    color: blue
}
.download-invoice-loader {
    color: lightblue
}
.quick-assign-btn {
    background-color: #01b59c;
    border-color: #01b59c;
}
.quick-assign-btn:hover {
    background-color: #01b59c;
    border-color: #005045;
}