@import "../../../constants/colors.scss";

.dashboard-header-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  min-height: 60px;
  width: 100%;
  padding: 0px 26px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.dashboard-header-user-profile-container {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
}
.dashboard-header-user-profile-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;
}
.vertical-line {
  height: 45px;
  border-right: 1px solid lightgray;
}
.menu-box {
  padding: 14px 20px;
  cursor: pointer;
}
.active-menu-box {
  background-color: #01b59c;
  color: white;
}
